import { useContext, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { MapContext } from "../../config/map";
import { IFazenda, IFazendaList, ITalhao } from "../../reducers/FazendaReducer";
import { setEquipamento } from "../../store/equipamentoActions";
import { setTalhao } from "../../store/fazendaActions";
import {
    setFocus,
    pushMarker,
    remMarkers,
    pushAllMarkers,
    remAllMarkers,
    ativarProcess,
    pushPolygon,
    remPolygons,
    pushMarkerAntena,
    remMarkerAntena,
    pushFlagMarker,
    remFlagMarker,
    pushOverlayEquipa,
    remOverlayEquipa,
    pushNotify,
    remNotify,
    setStatusSuccess,
    setRefresh,
    setStatusNull,
    pushParada,
    remParada,
} from "../../store/actions";
import { IFoco, IStatusList, ITalhaoTablet } from "../../store/types";
import { IProcess, IProcessList } from "../../reducers/ProcessReducer";
import { IStore } from "../../store";
import { IOpBBox, IOperacoes } from "../../reducers/OperacaoReducer";
import {
    IEquipamento,
    IEquipamentoList,
} from "../../reducers/EquipamentoReducer";
import { IMarkers } from "../../reducers/MarkerReducer";
import { IMenu } from "../../reducers/MenuReducer";
import { IPolylineFiltro, IPolylineList } from "../../reducers/PolylineReducer";
import { ITime } from "../../reducers/TimeReducer";
import {
    IAntena,
    IAntenaList,
    IAntenaModule,
} from "../../reducers/AntenaReducer";
import { faFlag, faFlagCheckered } from "@fortawesome/free-solid-svg-icons";
import {
    pushPolylineRender,
    remPolylineRender,
} from "../../store/polylineAction";
import { ITipoOp, ITipoOpList } from "../../reducers/TipoOpReducer";
import { v4 as uuidv4 } from "uuid";
import { ISafra } from "../../reducers/SafraReducer";
import { ICultura } from "../../reducers/CulturaReducer";
import { geoContains } from "d3-geo";

import { setFazenda } from "../../store/fazendaActions";
import { IAlertaRender } from "../../reducers/AlertaReducer";
import { orange } from "@mui/material/colors";
import { IOperador, IOperadorList } from "../../reducers/OperadorReducer";
import { IImplemento, IImplementoList } from "../../reducers/ImplementoReducer";

interface IMapProps {
    alertaRender: IAlertaRender;
    antena: IAntena;
    antenas: IAntenaList;
    equipamento: IEquipamento;
    equipamentos: IEquipamentoList;
    equipaOverlay: any;
    fazendas: IFazendaList;
    fazenda: IFazenda;
    foco: IFoco;
    talhao: ITalhao;
    processo: IProcess;
    operacoes: IOperacoes;
    opBBox: IOpBBox;
    markers: IMarkers;
    menuState: IMenu;
    processoListState: IProcessList;
    polylines: IPolylineList;
    time: ITime;
    tipoOp: ITipoOp;
    tiposOp: ITipoOpList;
    safra: ISafra;
    cultura: ICultura;
    loader: IStatusList;

    polylineFiltro: any;
    polylineRender: any;

    setStatusSuccess: Function;

    ativarProcess: Function;
    setEquipamento: Function;
    setFocus: Function;
    setTalhao: Function;
    pushMarker: Function;
    remMarkers: Function;
    pushAllMarkers: Function;
    remAllMarkers: Function;
    pushMarkerAntena: Function;
    remMarkerAntena: Function;

    pushPolygon: Function;
    remPolygons: Function;
    pushFlagMarker: Function;
    remFlagMarker: Function;
    pushOverlayEquipa: Function;
    remOverlayEquipa: Function;
    pushNotify: Function;
    remNotify: Function;
    pushParada: Function;
    remParada: Function;

    pushPolylineRender: Function;
    remPolylineRender: Function;
    setRefresh: Function;
    setStatusNull: Function;

    setFazenda: Function;

    operador: IOperador;

    talhoes: ITalhaoTablet[];
    talhoesFiltro: ITalhaoTablet[];

    tipoOpParada: any;

    implementos: IImplementoList;
    operadores: IOperadorList;
}

function Map(props: IMapProps) {
    const {
        alertaRender,
        antena,
        antenas,
        equipamento,
        equipamentos,
        equipaOverlay,
        fazendas,
        fazenda,
        foco,
        talhao,
        processo,
        setFocus,
        setTalhao,
        menuState,
        loader,
        processoListState,
        polylines,
        polylineFiltro,
        polylineRender,
        operacoes,
        opBBox,
        time,
        tipoOp,
        tiposOp,
        safra,
        cultura,
        markers,
        setStatusSuccess,
        ativarProcess,
        setEquipamento,
        pushMarker,
        remMarkers,
        pushAllMarkers,
        remAllMarkers,
        pushPolygon,
        remPolygons,
        pushMarkerAntena,
        remMarkerAntena,
        pushFlagMarker,
        remFlagMarker,
        remOverlayEquipa,
        pushOverlayEquipa,
        pushNotify,
        remNotify,
        pushParada,
        remParada,
        pushPolylineRender,
        remPolylineRender,
        setRefresh,
        setStatusNull,

        setFazenda,

        operador,

        talhoes,
        talhoesFiltro,

        tipoOpParada,

        implementos,
        operadores,
    } = props;

    const [talhaoRes, setTalhaoRes] = useState([]);

    function between(min: any, max: any) {
        return Math.floor(Math.random() * (max - min) + min);
    }

    function formatTime(data: Date) {
        return (
            data.toLocaleDateString("pt-BR", {
                day: "2-digit",
                month: "2-digit",
                year: "2-digit",
            }) +
            " - " +
            data.toLocaleTimeString("pt-BR")
        );
    }

    const maps = window.google.maps;
    const mapa: any = useContext(MapContext);
    let maxZoomService: google.maps.MaxZoomService;

    function initMap() {
        // Essa função inicia o mapa
        mapa.map = new maps.Map(mapContainer.current, {
            center: { lat: -15.2, lng: -54.3 },
            zoom: 12,
            minZoom: 11,
            disableDefaultUI: true,
            mapTypeControl: false,
            gestureHandling: "auto", //para desabilitar interação de pan
            zoomControl: false, //para desabilitar interação de zoom
            keyboardShortcuts: false,
        });
        mapa.map.setMapTypeId(maps.MapTypeId.HYBRID);
        mapa.map.addListener("click", (e: any) => {});
        mapa.map.addListener("bounds_changed", () => {
            checkZoom();
        });
    }
    //
    function checkZoom() {
        maxZoomService = new google.maps.MaxZoomService();

        if (mapa.map.getBounds() !== undefined) {
            maxZoomService.getMaxZoomAtLatLng(
                mapa.map.getBounds().getCenter(),
                (result: google.maps.MaxZoomResult) => {
                    if (result.status !== "OK") {
                        console.log("Error in MaxZoomService");
                    } else {
                        mapa.map.setOptions({ maxZoom: result.zoom });
                    }
                }
            );
        }
    }

    function makeFit(lX: any, lY: any, hX: any, hY: any) {
        //
        let centerX = new maps.LatLng(lX, lY);
        let centerY = new maps.LatLng(hX, hY);
        let bounds = new maps.LatLngBounds(centerY, centerX);

        mapa.map && mapa.map.fitBounds(bounds);
    }
    interface EstiloObjeto {
        fill: {
            color: string;
            opacity: number;
        };
        stroke: {
            color: string;
            opacity: number;
            weight: number;
        };
    }

    // function fazendaGeoJson(geo: any, campo: any, estilo: any, faz: any) {
    function fazendaGeoJson(props: any) {
        // Função para adicionar POLÍGONOS
        let data = new maps.Data({ map: mapa.map });
        let tooltip = new maps.InfoWindow();
        let shape = data.addGeoJson(JSON.parse(props.shape), props.id_fazenda);

        data.setStyle(() => {
            return {
                fillColor: JSON.parse(props.estilo).fill.color,
                fillOpacity: JSON.parse(props.estilo).fill.opacity / 100,
                strokeColor: JSON.parse(props.estilo).stroke.color,
                strokeOpacity: JSON.parse(props.estilo).stroke.opacity / 100,
                strokeWeight: JSON.parse(props.estilo).stroke.weight,
                zIndex: 11,
            };
        });
        data.addListener("mouseover", (e: any) => {
            data.overrideStyle(e.feature, {
                fillOpacity: 0.6,
                fillColor: JSON.parse(props.estilo).fill.color,
            });
        });
        data.addListener("mouseout", (e: any) => {
            data.revertStyle();
        });
        data.addListener("click", (e: any) => {
            if (
                window.screen.width < 920 ||
                (window.screen.width < 991.98 &&
                    window.screen.width > window.screen.height)
            ) {
            } else {
                let geoTalhao: any = [];
                if (talhaoRes.length > 0) {
                    geoTalhao = talhaoRes.map((g: any) => {
                        if (g.coordenadas !== null) {
                            return {
                                ...g,
                                coordenadas: {
                                    ...JSON.parse(g.coordenadas),
                                    type: "Polygon",
                                },
                            };
                        }
                    });
                }

                let talhaoAtual: any = [];
                if (geoTalhao.length > 0) {
                    talhaoAtual = geoTalhao.filter((g: any) => {
                        if (g !== undefined) {
                            return (
                                geoContains(g.coordenadas, [
                                    Number(e.latLng.lng()),
                                    Number(e.latLng.lat()),
                                ]) === true
                            );
                        } else {
                            tooltip.setContent(
                                "Talhão: " +
                                    e.feature.Fg[JSON.parse(props.campo).nome]
                            );
                            setTalhao(e.feature.Fg["id_agrochip"]);
                        }
                    });
                } else {
                    tooltip.setContent(
                        "Talhão: " + e.feature.Fg[JSON.parse(props.campo).nome]
                    );
                    setTalhao(e.feature.Fg["id_agrochip"]);
                }

                if (talhaoAtual.length > 0) {
                    tooltip.setContent("Talhão: " + talhaoAtual[0].nome_talhao);
                    setTalhao(talhaoAtual[0].id_talhao);
                } else {
                    tooltip.setContent(
                        "Talhão: " + e.feature.Fg[JSON.parse(props.campo).nome]
                    );
                    setTalhao(e.feature.Fg["id_agrochip"]);
                }

                tooltip.setPosition(e.latLng);
                setFocus({ view: 2, subView: null });
            }
        });
        maps.event.addListener(mapa.map, "click", function (event: any) {
            tooltip.close();
        });

        return data;
    }

    function labelTalhao(bbox: any, size: any, nome: any, campo: string) {
        let bboxSw = new maps.LatLng(bbox[1], bbox[0]);
        let bboxNe = new maps.LatLng(bbox[3], bbox[2]);
        let bboxGoogle = new maps.LatLngBounds(bboxSw, bboxNe);

        let iconCircle = {
            path: maps.SymbolPath.CIRCLE,
            fillColor: "#FFFFFFF",
            fillOpacity: 1,
            strokeWeight: 0,
            rotation: 0,
            scale: size,
            anchor: new maps.Point(0, 0),
        };
        const icon = new maps.Marker({
            position: bboxGoogle.getCenter(),
            map: mapa.map,
            icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjyHQt+g8ABFsCIF75EPIAAAAASUVORK5CYII=",
            // icon: iconCircle,
            optimized: true,
            label: {
                fontFamily: "Nunito",
                fontSize: size + "px",
                color: "#FFF",
                className: "label-talhao",
                text: nome[JSON.parse(campo).nome],
            },
        });

        return icon;
    }

    function equipaMarkerFoco(
        lat: any,
        lng: any,
        // cor: any,
        size: any,
        equipa: IEquipamento
        //icone: any
    ) {
        let equipaEstado = "";

        switch (equipa.estado) {
            case "-1":
                equipaEstado = "Inativo";
                break;
            case "0":
                equipaEstado = "Em Manutenção";
                break;
            case "1":
                equipaEstado = "Ativo";
                break;
            default:
                break;
        }

        let equipaCor = "";

        // if (equipa.last_seen && equipa.ultima_operacao) {
        //     let atual = new Date().getTime();
        //     let ult = new Date(equipa.last_seen).getTime();
        //     let ultReg = new Date(equipa.ultima_operacao.timestamp).getTime();

        //     if (atual - ult > 720000) {
        //         equipaCor = equipa.ultima_operacao.motorON ? "red" : "#000";
        //     } else {
        //         if (atual - ultReg < 720000) {
        //             equipaCor = equipa.ultima_operacao.motorON
        //                 ? equipa.ultima_operacao.tipo_operacao_parada_temp
        //                     ? "yellow"
        //                     : "green"
        //                 : "#000";
        //         } else {
        //             equipaCor = equipa.ultima_operacao.motorON
        //                 ? "orange"
        //                 : "#000";
        //         }
        //     }
        // } else {
        //     equipaCor = "#000";
        // }

        if (
            equipa.last_seen &&
            equipa.last_seen_type &&
            equipa.ultima_operacao
        ) {
            let atual = new Date().getTime();
            let ult = new Date(equipa.last_seen).getTime();
            let ultReg = new Date(equipa.ultima_operacao.timestamp).getTime();

            if (atual - ult > 360000) {
                equipaCor = equipa.ultima_operacao.motorON ? "red" : "#000";
            } else {
                // if (equipa.last_seen_type === "OPER") {
                if (atual - ultReg < 360000) {
                    if (equipa.last_seen_type === "KEEP") {
                        equipaCor = equipa.ultima_operacao.motorON
                            ? "green"
                            : "#000";
                    } else {
                        equipaCor = equipa.ultima_operacao.motorON
                            ? equipa.ultima_operacao
                                  .tipo_operacao_parada_temp !== undefined
                                ? "yellow"
                                : "green"
                            : "#000";
                    }
                } else {
                    equipaCor = equipa.ultima_operacao.motorON
                        ? "orange"
                        : "#000";
                }
                // } else if (equipa.last_seen_type === "KEEP") {
                //     equipaCor = equipa.ultima_operacao.motorON
                //         ? "green"
                //         : "#000";
                // }
            }
        } else {
            equipaCor = "#000";
        }

        let iconCircle = {
            path: maps.SymbolPath.CIRCLE,
            fillColor: equipaCor,
            fillOpacity: 0.5,
            // strokeColor: equipa.last_seen
            //     ? new Date().getTime() - new Date(equipa.last_seen).getTime() >
            //       600000
            //         ? "red"
            //         : "green"
            //     : "rgba(255, 255, 255, 0.5)",
            strokeWeight: 0,
            rotation: 0,
            scale: size,
            anchor: new maps.Point(0, 0),
        };

        const icon = new maps.Marker({
            position: new maps.LatLng(lat, lng),
            map: mapa.map,
            icon: iconCircle,
            optimized: true,
            label: {
                fontFamily: "agrochip",
                fontSize: size + 8 + "px",
                color: "#fff",
                text: equipa.tipo === undefined ? "" : equipa.tipo,
            },
            zIndex: 1200,
        });

        if (equipa.id_equipamento) {
            let operacao: any = "";
            let parada: any = "";
            let ult: any = "";
            let status: any = "";
            let statusEquipa: any = "";
            let implemento: any = "";
            let ope: any = "";
            let vel: any = "";
            let rpm: any = "";

            if (equipa.ultima_operacao !== undefined) {
                ult =
                    '<i class="far fa-clock" style="margin-right: 5px;"></i>' +
                    formatTime(new Date(equipa.ultima_operacao.timestamp));
                if (equipa.ultima_operacao.motorON) {
                    if (equipa.ultima_operacao.velocidade !== undefined) {
                        vel =
                            '<div style="display: flex; align-items: center; margin-top: 10px;">' +
                            '<i class="fas fa-tachometer-alt" style="margin-right: 5px;"></i>' +
                            equipa.ultima_operacao.velocidade +
                            "Km/h" +
                            "</div>";
                    }
                    if (equipa.ultima_operacao.rpm !== undefined) {
                        if (equipa.ultima_operacao.rpm > 0) {
                            rpm =
                                '<div style="display: flex; align-items: center; margin-top: 10px;">' +
                                '<i class="fas fa-sync" style="margin-right: 5px;"></i>' +
                                equipa.ultima_operacao.rpm +
                                " RPM" +
                                "</div>";
                        }
                    }
                }

                if (
                    equipa.ultima_operacao.tipo_operacao_parada_temp !==
                    undefined
                ) {
                    if (
                        equipa.ultima_operacao.tipo_operacao_parada_temp !== 0
                    ) {
                        parada =
                            '<div style="display: flex; align-items: center; margin-top: 10px;">' +
                            '<i class="fas fa-stop-circle" style="margin-right: 5px;"></i>' +
                            tiposOp[
                                tiposOp.findIndex(
                                    (t: any) =>
                                        t.id_tipo_operacao ===
                                        equipa.ultima_operacao
                                            .tipo_operacao_parada_temp
                                )
                            ].descricao +
                            "</div>";
                    } else {
                        parada =
                            '<div style="display: flex; align-items: center; margin-top: 10px;">' +
                            '<i class="fas fa-stop-circle" style="margin-right: 5px;"></i>Não Identificado' +
                            "</div>";
                    }
                }

                if (equipa.last_seen_type === "OPER") {
                    if (equipa.ultima_operacao.motorON) {
                        if (
                            equipa.ultima_operacao.tipo_operacao_temp !==
                            undefined
                        ) {
                            if (
                                equipa.ultima_operacao.tipo_operacao_temp !== 0
                            ) {
                                operacao =
                                    '<i class="fas fa-hard-hat" style="margin-right: 5px;"></i>' +
                                    tiposOp[
                                        tiposOp.findIndex(
                                            (t: any) =>
                                                t.id_tipo_operacao ===
                                                equipa.ultima_operacao
                                                    .tipo_operacao_temp
                                        )
                                    ].descricao;
                            } else {
                                operacao =
                                    '<div style="display: flex; align-items: center; margin-top: 10px;">' +
                                    '<i class="fas fa-hard-hat" style="margin-right: 5px;"></i>Não Identificado' +
                                    "</div>";
                            }
                        } else if (
                            equipa.ultima_operacao.velocidade !== undefined
                        ) {
                            if (equipa.ultima_operacao.velocidade > 0) {
                                operacao =
                                    '<div style="display: flex; align-items: center; margin-top: 10px;">' +
                                    '<i class="fas fa-hard-hat" style="margin-right: 5px;"></i>Não Identificado' +
                                    "</div>";
                            }
                        }

                        if (equipa.ultima_operacao.operador_temp) {
                            if (
                                operadores.findIndex(
                                    (o: IOperador) =>
                                        o.id_operador ===
                                        equipa.ultima_operacao.operador_temp
                                ) < 0
                            ) {
                                ope =
                                    '<div style="display: flex; align-items: center; margin-top: 10px;">' +
                                    '<i class="fas fa-user-cog" style="margin-right: 5px;"></i>Operador não encontrado' +
                                    "</div>";
                            } else {
                                ope =
                                    '<div style="display: flex; align-items: center; margin-top: 10px;">' +
                                    '<i class="fas fa-user-cog" style="margin-right: 5px;"></i>' +
                                    operadores[
                                        operadores.findIndex(
                                            (o: IOperador) =>
                                                o.id_operador ===
                                                equipa.ultima_operacao
                                                    .operador_temp
                                        )
                                    ].nome +
                                    "</div>";
                            }
                        } else {
                            ope =
                                '<div style="display: flex; align-items: center; margin-top: 10px;">' +
                                '<i class="fas fa-user-cog" style="margin-right: 5px;"></i>Não Identificado' +
                                "</div>";
                        }
                    }
                }

                if (equipa.ultima_operacao.implemento_temp) {
                    implemento =
                        '<div style="display: flex; align-items: center; margin-top: 10px;">' +
                        '<i class="fas fa-cogs" style="margin-right: 5px;"></i>' +
                        implementos[
                            implementos.findIndex(
                                (i: IImplemento) =>
                                    i.id_implemento ===
                                    equipa.ultima_operacao.implemento_temp
                            )
                        ].descricao +
                        "</div>";
                }

                // if (equipa.last_seen) {
                //     let atual = new Date().getTime();
                //     let ult = new Date(equipa.last_seen).getTime();
                //     let ultReg = new Date(
                //         equipa.ultima_operacao.timestamp
                //     ).getTime();

                //     if (equipa.ultima_operacao.motorON) {
                //         if (atual - ult > 720000) {
                //             statusEquipa = "Sem Sinal";
                //         } else {
                //             if (atual - ultReg < 720000) {
                //                 statusEquipa = "Ligado - Online";
                //             } else {
                //                 statusEquipa = "Em histórico";
                //             }
                //         }
                //     } else statusEquipa = "Desligado";
                // }

                if (equipa.last_seen && equipa.last_seen_type) {
                    let atual = new Date().getTime();
                    let ult = new Date(equipa.last_seen).getTime();
                    let ultReg = new Date(
                        equipa.ultima_operacao.timestamp
                    ).getTime();

                    if (equipa.ultima_operacao.motorON) {
                        if (atual - ult > 360000) {
                            statusEquipa = "Sem Sinal";
                        } else {
                            // if (equipa.last_seen_type === "OPER") {
                            if (atual - ultReg < 360000) {
                                if (equipa.last_seen_type === "KEEP") {
                                    statusEquipa = "Ligado - Sinal Baixo";
                                } else statusEquipa = "Ligado - Online";
                            } else statusEquipa = "Em histórico";
                            // } else if (equipa.last_seen_type === "KEEP") {
                            //     statusEquipa = "Ligado - Sinal Baixo";
                            // }
                        }
                    } else statusEquipa = "Desligado";
                } else {
                    statusEquipa = "Sem Informação Atual";
                }

                status = statusEquipa;
            }

            var info = new maps.InfoWindow();
            icon.addListener("mouseover", (e: any) => {
                info.setContent(
                    '<div style="overflow-x: hidden;"><div style="font-size: 15px; font-weight: bold; color: #000;">' +
                        equipa.numero_patrimonio +
                        " - " +
                        equipa.descricao +
                        '</div><div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 7px; margin-top: 10px;">' +
                        '<div style="display: flex; align-items: center;">' +
                        '<i class="fas fa-info-circle" style="margin-right: 5px;"></i>' +
                        equipaEstado +
                        "</div>" +
                        '<div style="display: flex; align-items: center;">' +
                        '<i class="fas fa-power-off" style="margin-right: 5px;"></i>' +
                        status +
                        "</div>" +
                        "</div>" +
                        '</div><div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 7px; margin-top: 10px;">' +
                        '<div style="display: flex; align-items: center;">' +
                        ult +
                        "</div>" +
                        '<div style="display: flex; align-items: center;">' +
                        operacao +
                        "</div>" +
                        "</div>" +
                        '</div><div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 7px;">' +
                        '<div style="display: flex; align-items: center;">' +
                        vel +
                        "</div>" +
                        '<div style="display: flex; align-items: center;">' +
                        rpm +
                        "</div>" +
                        "</div>" +
                        ope +
                        parada +
                        implemento +
                        "</div>"
                );
                info.open(mapa.map, icon);
            });
            icon.addListener("mouseout", (e: any) => {
                info.close();
            });
            icon.addListener("click", (e: any) => {
                if (
                    window.screen.width < 920 ||
                    (window.screen.width < 991.98 &&
                        window.screen.width > window.screen.height)
                ) {
                    info.setContent(
                        '<div style="overflow-x: hidden;"><div style="font-size: 15px; font-weight: bold; color: #000;">' +
                            equipa.numero_patrimonio +
                            " - " +
                            equipa.descricao +
                            '</div><div style="display: grid; grid-template-columns: auto 100px; grid-gap: 7px; margin-top: 10px;">' +
                            '<div style="display: flex; align-items: center;">' +
                            '<i class="fas fa-info-circle" style="margin-right: 5px;"></i>' +
                            equipaEstado +
                            "</div>" +
                            '<div style="display: flex; align-items: center;justify-content: flex-start">' +
                            '<i class="fas fa-power-off" style="margin-right: 5px;"></i>' +
                            status +
                            "</div>" +
                            "</div>" +
                            '<div style="display: grid; grid-template-columns: auto 100px; grid-gap: 7px; margin-top: 10px;">' +
                            '<div style="display: flex; align-items: center;">' +
                            ult +
                            "</div>" +
                            '<div style="display: flex; align-items: center;justify-content: flex-start;">' +
                            operacao +
                            "</div>" +
                            "</div>" +
                            '<div style="display: grid; grid-template-columns: auto 100px; grid-gap: 7px;">' +
                            '<div style="display: flex; align-items: center;">' +
                            vel +
                            "</div>" +
                            '<div style="display: flex; align-items: center; justify-content: flex-start">' +
                            rpm +
                            "</div>" +
                            "</div>" +
                            ope +
                            parada +
                            implemento +
                            "</div>"
                    );
                    info.open(mapa.map, icon);

                    maps.event.addListener(mapa.map, "bounds_changed", () => {
                        info.close();
                    });
                } else {
                    setEquipamento(equipa);
                    ativarProcess({
                        menuItem:
                            menuState[
                                menuState.findIndex((m: any) => m.id === 2)
                            ],
                        processList: processoListState,
                    });
                    setFocus({ view: 3, subView: null });
                }
            });
        }

        return icon;
    }

    function equipaBBoxTeste(lat: any, lng: any, cor: any) {
        let iconCircle = {
            path: maps.SymbolPath.CIRCLE,
            fillColor: cor,
            fillOpacity: 0.5,
            strokeWeight: 0,
            rotation: 0,
            scale: 10,
            anchor: new maps.Point(0, 0),
        };

        const icon = new maps.Marker({
            position: new maps.LatLng(lat, lng),
            map: mapa.map,
            icon: iconCircle,
            optimized: true,
        });

        return icon;
    }

    // function equipaCoordMarker(lat: any, lng: any, type: string) {
    //     //Caso o usuário opte por Pontos
    //     let iconCircle = {
    //         path: maps.SymbolPath.CIRCLE,
    //         fillColor: "red",
    //         fillOpacity: 0.5,
    //         strokeColor: "red",
    //         strokeOpacity: 1,
    //         strokeWeight: 1,
    //         rotation: 0,
    //         scale: 5,
    //         anchor: new maps.Point(0, 0),
    //     };

    //     const icon = new maps.Marker({
    //         position: new maps.LatLng(lat, lng),
    //         map: mapa.map,
    //         icon: iconCircle,
    //         optimized: true,
    //     });

    //     return icon;
    // }

    function equipaCoordPolyline(array: any, cor: any, valido?: boolean) {
        //Caso o usuário opte por polylines
        let rangeMVC: any = array.map((p: any) => {
            if (valido) {
                if (p.motorON) {
                    return {
                        position: new google.maps.LatLng(
                            parseFloat(p.latitude),
                            parseFloat(p.longitude)
                        ),
                        velocidade: p.velocidade, // Adicionando velocidade para cada ponto
                        rpm: p.rpm, // Adicionando rpm para cada ponto
                        timestamp: p.timestamp, // Adicionando timestamp para cada ponto
                        tipo_operacao_temp: p.tipo_operacao_temp, // Adicionando tipo de operação para cada ponto
                    };
                } else {
                    return {
                        position: new google.maps.LatLng(
                            parseFloat(p.latitude),
                            parseFloat(p.longitude)
                        ),
                    };
                }
            } else {
                return {
                    position: new google.maps.LatLng(
                        parseFloat(p.latitude),
                        parseFloat(p.longitude)
                    ),
                };
            }
        });

        // Criando a array de caminho usando os pontos com posições
        let caminhoEquipamento = new google.maps.MVCArray<any>(
            rangeMVC.map((p: any) => p.position)
        );

        let rotaEquipa = new maps.Polyline({
            path: caminhoEquipamento,
            map: mapa.map,
            geodesic: false,
            strokeColor: cor,
            strokeOpacity: 0.6,
            strokeWeight: 5,
            zIndex: 1001,
        });

        // let distancia = google.maps.geometry.spherical
        //     .computeLength(caminhoEquipamento)
        //     .toFixed(2);
        // console.log(
        //     "a distancia percorrida entre as coordenadas é aproximadamente " +
        //         distancia +
        //         " metros"
        // );
        // console.log(
        //     maps.geometry.spherical.computeArea(caminhoEquipamento).toFixed(2)
        // );

        const infoPoly = new google.maps.InfoWindow();

        if (valido) {
            // Evento de clique na Polyline para mostrar informações detalhadas
            rotaEquipa.addListener("click", (e: any) => {
                // Obtendo todos os pontos da Polyline
                const pathArray = rotaEquipa.getPath().getArray();

                // Variáveis para armazenar o ponto e índice mais próximos ao clique
                let pontoMaisProximo = null;
                let menorDistancia = Infinity;
                let indicePonto = 0;

                // Iterando sobre os segmentos para encontrar o ponto exato
                for (let i = 0; i < pathArray.length - 1; i++) {
                    const pontoInicial = pathArray[i];
                    const pontoFinal = pathArray[i + 1];

                    // Encontrando a posição exata na Polyline onde o clique ocorreu
                    const distanciaClique =
                        google.maps.geometry.spherical.computeDistanceBetween(
                            pontoInicial,
                            e.latLng
                        );
                    const distanciaTotal =
                        google.maps.geometry.spherical.computeDistanceBetween(
                            pontoInicial,
                            pontoFinal
                        );
                    const interpolacao = distanciaClique / distanciaTotal;

                    if (interpolacao >= 0 && interpolacao <= 1) {
                        const pontoInterpolado =
                            google.maps.geometry.spherical.interpolate(
                                pontoInicial,
                                pontoFinal,
                                interpolacao
                            );
                        const distanciaAoClique =
                            google.maps.geometry.spherical.computeDistanceBetween(
                                e.latLng,
                                pontoInterpolado
                            );

                        if (distanciaAoClique < menorDistancia) {
                            menorDistancia = distanciaAoClique;
                            pontoMaisProximo = pontoInterpolado;
                            indicePonto = i;
                        }
                    }
                }

                // Exibindo as informações do ponto exato
                if (pontoMaisProximo) {
                    const timeNoPonto = rangeMVC[indicePonto].timestamp;

                    let ult: any = "";
                    let vel: any = "";
                    let rpm: any = "";
                    let operacao: any = "";

                    if (timeNoPonto !== undefined) {
                        ult =
                            '<i class="far fa-clock" style="margin-right: 5px;"></i>' +
                            formatTime(new Date(timeNoPonto));

                        if (rangeMVC[indicePonto].velocidade !== undefined) {
                            vel =
                                '<div style="display: flex; align-items: center; margin-top: 10px;">' +
                                '<i class="fas fa-tachometer-alt" style="margin-right: 5px;"></i>' +
                                rangeMVC[indicePonto].velocidade +
                                "Km/h" +
                                "</div>";
                        }
                        if (rangeMVC[indicePonto].rpm !== undefined) {
                            rpm =
                                '<div style="display: flex; align-items: center; margin-top: 10px;">' +
                                '<i class="fas fa-sync" style="margin-right: 5px;"></i>' +
                                rangeMVC[indicePonto].rpm +
                                " RPM" +
                                "</div>";
                        }

                        if (
                            rangeMVC[indicePonto].tipo_operacao_temp !==
                            undefined
                        ) {
                            if (
                                rangeMVC[indicePonto].tipo_operacao_temp !== 0
                            ) {
                                operacao =
                                    '<i class="fas fa-hard-hat" style="margin-right: 5px;"></i>' +
                                    tiposOp[
                                        tiposOp.findIndex(
                                            (t: any) =>
                                                t.id_tipo_operacao ===
                                                rangeMVC[indicePonto]
                                                    .tipo_operacao_temp
                                        )
                                    ].descricao;
                            } else {
                                operacao =
                                    '<div style="display: flex; align-items: center;">' +
                                    '<i class="fas fa-hard-hat" style="margin-right: 5px;"></i>Não Identificado' +
                                    "</div>";
                            }
                        } else if (
                            rangeMVC[indicePonto].velocidade !== undefined
                        ) {
                            if (rangeMVC[indicePonto].velocidade > 0) {
                                operacao =
                                    '<div style="display: flex; align-items: center;">' +
                                    '<i class="fas fa-hard-hat" style="margin-right: 5px;"></i>Não Identificado' +
                                    "</div>";
                            }
                        }

                        infoPoly.setContent(
                            '<div style="overflow-x: hidden;">' +
                                '<div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 7px;">' +
                                '<div style="display: flex; align-items: center;">' +
                                ult +
                                "</div>" +
                                '<div style="display: flex; align-items: center;">' +
                                operacao +
                                "</div>" +
                                "</div>" +
                                '<div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 7px;">' +
                                '<div style="display: flex; align-items: center;">' +
                                vel +
                                "</div>" +
                                '<div style="display: flex; align-items: center;">' +
                                rpm +
                                "</div>" +
                                "</div>" +
                                "</div>"
                        );
                        infoPoly.setPosition(pontoMaisProximo);
                        infoPoly.open(mapa.map);
                    }
                }
            });
        }

        maps.event.addListener(
            mapa.map,
            "bounds_changed",
            function (event: any) {
                infoPoly.close();
            }
        );

        return rotaEquipa;
    }

    function antenaAdd(lat: any, lng: any, cor: any) {
        const symbolOne = {
            path: "M -3 2 L 0 -3 L 3 2 z",
            strokeColor: "#000",
            strokeWeight: 2,
            fillColor: cor,
            fillOpacity: 1,
            scale: 8,
            anchor: new maps.Point(0, 0),
        };

        const icon = new maps.Marker({
            position: new maps.LatLng(lat, lng),
            map: mapa.map,
            icon: symbolOne,
            optimized: true,
            label: {
                fontFamily: "Fontawesome",
                fontSize: "15px",
                color: "#000",
                text: "\uf519",
            },
        });

        return icon;
    }

    function equipaFlagMarker(lat: any, lng: any, modo: any) {
        let iconCircle = {
            path: maps.SymbolPath.CIRCLE,
            fillColor: "#fff",
            fillOpacity: 0.8,
            strokeWeight: 0,
            rotation: 0,
            scale: 20,
            anchor: new maps.Point(0, 0),
        };

        const icon = new maps.Marker({
            position: new maps.LatLng(lat, lng),
            map: mapa.map,
            optimized: true,
            icon: {
                url: modo ? "/pin-inicio.svg" : "/pin-fim.svg",
                scale: 50,
                //size: new maps.Size(30, 30),
                scaledSize: new maps.Size(50, 50),
                // //path: "M12 10c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2m0-5c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3m-7 2.602c0-3.517 3.271-6.602 7-6.602s7 3.085 7 6.602c0 3.455-2.563 7.543-7 14.527-4.489-7.073-7-11.072-7-14.527m7-7.602c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602",
                // path: faFlagCheckered.icon[4] as string,
                // fillColor: "blue",
                // fillOpacity: 1,
                // strokeColor: "blue",
                // strokeWeight: 1,
                anchor: new maps.Point(3, 50),
                // scale: 0.075,
            },
            // label: {
            //     fontFamily: "Fontawesome",
            //     fontSize: "20px",
            //     color: "#FFF",
            //     text: modo ? "\uf024" : "\uf11e",
            // },
        });

        return icon;
    }

    function notifyAdd(info: any) {
        const symbolOne = {
            path: "M -2,0 0,-2 2,0 0,2 z",
            strokeColor: "#000",
            strokeWeight: 2,
            fillColor: "#F0D000",
            fillOpacity: 1,
            scale: 8,
        };

        const icon = new maps.Marker({
            position: new maps.LatLng(info.latitude, info.longitude),
            map: mapa.map,
            icon: symbolOne,
            optimized: true,
            label: {
                fontFamily: "Fontawesome",
                fontSize: "15px",
                color: "#000",
                text: "\uf12a",
            },
        });

        let implemento: any = "";

        if (info.implemento_temp) {
            implemento =
                '<div><span class="titulo">Implemento:</span>' +
                "  " +
                implementos[
                    implementos.findIndex(
                        (i: IImplemento) =>
                            i.id_implemento === info.implemento_temp
                    )
                ].descricao +
                "</div>";
        }

        let dataH = new Date(info.timestamp);

        var infoJanela = new maps.InfoWindow();
        icon.addListener("click", () => {
            infoJanela.setContent(
                '<div class="info-alerta"> <div><span class="titulo">Alerta:</span>' +
                    info.txtalerta.split("-")[1] +
                    '</div> <div><span class="titulo">Operação:</span>' +
                    "  " +
                    (info.tipo_operacao_temp
                        ? tiposOp[
                              tiposOp.findIndex(
                                  (o: any) =>
                                      o.id_tipo_operacao ===
                                      info.tipo_operacao_temp
                              )
                          ].descricao
                        : "Não Identificado") +
                    '</div> <div><span class="titulo">Operador:</span>' +
                    "  " +
                    (info.operador_temp
                        ? operadores.findIndex(
                              (o: IOperador) =>
                                  o.id_operador === info.operador_temp
                          ) < 0
                            ? "Operador não encontrado"
                            : operadores[
                                  operadores.findIndex(
                                      (o: IOperador) =>
                                          o.id_operador === info.operador_temp
                                  )
                              ].nome
                        : "Não Identificado") +
                    '</div> <div><span class="titulo">Data/Hora:</span>' +
                    "  " +
                    dataH.toLocaleDateString("pt-BR", {
                        weekday: "long",
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                        hour12: true,
                    }) +
                    " - " +
                    dataH.toLocaleTimeString("pt-BR") +
                    "</div>" +
                    implemento +
                    "</div>"
            );
            infoJanela.open(mapa.map, icon);
        });

        maps.event.addListener(mapa.map, "bounds_changed", () => {
            infoJanela.close();
        });

        return icon;
    }

    function paradaAdd(lat: any, lng: any, cor: any) {
        let iconCircle = {
            path: maps.SymbolPath.CIRCLE,
            fillColor: cor,
            fillOpacity: 1,
            strokeColor: "white",
            strokeOpacity: 1,
            strokeWeight: 1,
            rotation: 0,
            scale: 6,
            anchor: new maps.Point(0, 0),
        };

        const icon = new maps.Marker({
            position: new maps.LatLng(lat, lng),
            map: mapa.map,
            icon: iconCircle,
            optimized: true,
        });

        return icon;
    }

    function overlayControle(lX: any, lY: any, hX: any, hY: any) {
        const bboxFazenda = [
            {
                lng: hY,
                lat: lX,
            },
            {
                lng: lY,
                lat: lX,
            },
            {
                lng: lY,
                lat: hX,
            },
            {
                lng: hY,
                lat: hX,
            },
            {
                lng: hY,
                lat: lX,
            },
        ];

        const fazendaPoligono = new maps.Polygon({
            paths: bboxFazenda,
            strokeColor: "green",
            strokeOpacity: 0,
            strokeWeight: 2,
            fillColor: "purple",
            fillOpacity: 0,
            zIndex: 1000,
            map: mapa.map,
        });

        fazendaPoligono.addListener("click", (e: any) => {});

        return fazendaPoligono;
    }

    useEffect(() => {
        initMap();
    }, []);

    // useEffect(() => {
    //     console.log(
    //         JSON.parse(
    //             fazendas[
    //                 fazendas.findIndex(
    //                     (f: any) => f.id_fazenda === fazenda.id_fazenda
    //                 )
    //             ].shape
    //         )
    //     ); //
    // }, [fazendas, fazenda]);

    useEffect(() => {
        if (fazenda.id_fazenda !== "") {
            const fetchTalhao = async () => {
                const res = await fetch(
                    `${process.env.REACT_APP_BASE_URL_API}api/talhao/${fazenda.id_fazenda}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json;charset=UTF-8",
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    }
                );

                let resJSON = JSON.parse(await res.text());

                setTalhaoRes(resJSON);
            };

            fetchTalhao();
        }
    }, [fazenda]);

    useEffect(() => {
        if (mapa.shp !== undefined) {
            mapa.shp.forEach((m: any) => {
                m.shape.setMap(null);
            });
        }

        mapa.shp = [];
        mapa.labels = [];
        fazendas.forEach((f: IFazenda) => {
            mapa.shp.push({
                id: f.id_fazenda,
                shape: fazendaGeoJson(f),
            });
            JSON.parse(f.shape).features.forEach((fd: GeoJSON.Feature) => {
                if (typeof f.campo === "string") {
                    mapa.labels.push({
                        id: uuidv4(),
                        label: labelTalhao(
                            fd.geometry.bbox,
                            "10",
                            fd.properties,
                            f.campo
                        ),
                    });
                }
            });
        });
    }, [fazendas, fazenda, talhaoRes]);

    useEffect(() => {
        setFazenda(
            fazendas[
                fazendas.findIndex(f => f.id_fazenda === fazenda.id_fazenda)
            ]
        );
    }, [fazendas]);

    useEffect(() => {
        remMarkers();
        remAllMarkers();
        remPolygons();
        remMarkerAntena();
        remFlagMarker();
        remOverlayEquipa();
        remNotify();
        remParada();
    }, [foco, equipamento, time]);

    useEffect(() => {
        remPolylineRender();
    }, [tipoOp, safra, cultura, foco, polylineFiltro]);

    useEffect(() => {
        remOverlayEquipa();
        remParada();
    }, [polylines]);

    useEffect(() => {
        remAllMarkers();
    }, [foco, processo.side?.ativo, time, fazenda, equipamentos]);

    useEffect(() => {
        switch (foco.view) {
            case 1: //Foco na fazenda
                if (fazenda.coordenadas !== "") {
                    let fazCoord = JSON.parse(fazenda.coordenadas);
                    makeFit(
                        fazCoord.lowX, //
                        fazCoord.lowY,
                        fazCoord.highX,
                        fazCoord.highY
                    );
                    if (processo.side?.ativo) {
                        if (mapa.map) {
                            mapa.map.panBy(150, 0);
                        }
                    }
                }
                break;
            case 2: //Foco no Talhão
                if (fazenda !== null) {
                    let talKey: any;
                    if (talhoesFiltro.length > 0) {
                        talKey = talhoesFiltro.findIndex(
                            (t: any) => t.id_talhao === talhao
                        );
                    }

                    let listaTalhao: any = [];
                    let listaTalhaoTablet: any = [];
                    if (talKey > -1) {
                        listaTalhaoTablet = talhoesFiltro.map((g: any) => {
                            if (g.coordenadas !== null) {
                                return {
                                    ...g,
                                    coordenadas: {
                                        ...JSON.parse(g.coordenadas),
                                        type: "Polygon",
                                    },
                                };
                            }
                        });
                    } else {
                        talKey = JSON.parse(fazenda.shape).features.findIndex(
                            (t: any) => t.properties["id_agrochip"] === talhao
                        );
                        listaTalhao = JSON.parse(fazenda.shape);
                    }

                    let equipas = equipamentos.filter((e: any) => {
                        if (e.ultima_operacao !== undefined) {
                            if (listaTalhaoTablet.length > 0) {
                                if (listaTalhaoTablet[talKey] !== undefined) {
                                    if (e.ultima_operacao.talhao_temp) {
                                        return (
                                            listaTalhaoTablet[talKey]
                                                .id_talhao ===
                                            e.ultima_operacao.talhao_temp
                                        );
                                    } else
                                        return (
                                            geoContains(
                                                listaTalhaoTablet[talKey]
                                                    .coordenadas,
                                                [
                                                    Number(
                                                        e.ultima_operacao
                                                            .longitude
                                                    ),
                                                    Number(
                                                        e.ultima_operacao
                                                            .latitude
                                                    ),
                                                ]
                                            ) === true
                                        );
                                } else return false;
                            } else {
                                return (
                                    geoContains(listaTalhao.features[talKey], [
                                        Number(e.ultima_operacao.longitude),
                                        Number(e.ultima_operacao.latitude),
                                    ]) === true
                                );
                            }
                        } else {
                            return false;
                        }
                    });
                    equipas.forEach((e: IEquipamento) => {
                        if (e.estado !== "-1") {
                            pushAllMarkers({
                                id: e.id_equipamento,
                                icon: equipaMarkerFoco(
                                    e.ultima_operacao?.latitude,
                                    e.ultima_operacao?.longitude,
                                    15,
                                    e
                                ),
                            });
                        }
                    });
                    if (talKey > -1) {
                        if (listaTalhaoTablet.length > 0) {
                            if (listaTalhaoTablet[talKey] !== undefined) {
                                makeFit(
                                    listaTalhaoTablet[talKey].coordenadas
                                        .bbox[3],
                                    listaTalhaoTablet[talKey].coordenadas
                                        .bbox[2],
                                    listaTalhaoTablet[talKey].coordenadas
                                        .bbox[1],
                                    listaTalhaoTablet[talKey].coordenadas
                                        .bbox[0]
                                );
                            } else {
                                let fazCoord = JSON.parse(fazenda.coordenadas);
                                makeFit(
                                    fazCoord.lowX, //
                                    fazCoord.lowY,
                                    fazCoord.highX,
                                    fazCoord.highY
                                );
                            }
                        } else {
                            makeFit(
                                listaTalhao.features[talKey].geometry.bbox[3],
                                listaTalhao.features[talKey].geometry.bbox[2],
                                listaTalhao.features[talKey].geometry.bbox[1],
                                listaTalhao.features[talKey].geometry.bbox[0]
                            );
                        }
                    } else {
                        let fazCoord = JSON.parse(fazenda.coordenadas);
                        makeFit(
                            fazCoord.lowX, //
                            fazCoord.lowY,
                            fazCoord.highX,
                            fazCoord.highY
                        );
                    }

                    if (processo.side?.ativo) {
                        if (mapa.map) {
                            mapa.map.panBy(150, 0);
                        }
                    }
                }
                break;
            case 3: //Foco em um equipamento
                if (
                    equipamento.estado !== "-1" &&
                    equipamento.ultima_operacao?.latitude &&
                    equipamento.ultima_operacao?.longitude
                ) {
                    pushMarker({
                        id: equipamento.id_equipamento,
                        icon: equipaMarkerFoco(
                            equipamento.ultima_operacao?.latitude,
                            equipamento.ultima_operacao?.longitude,
                            25,
                            equipamento
                        ),
                    });
                    let center = new maps.LatLng(
                        parseFloat(equipamento.ultima_operacao?.latitude),
                        parseFloat(equipamento.ultima_operacao?.longitude)
                    );
                    mapa.map.setZoom(17);
                    mapa.map.panTo(center);
                } else {
                    let fazCoord = JSON.parse(fazenda.coordenadas);
                    makeFit(
                        fazCoord.lowX, //
                        fazCoord.lowY,
                        fazCoord.highX,
                        fazCoord.highY
                    );
                }
                if (processo.side?.ativo) {
                    if (mapa.map) {
                        mapa.map.panBy(150, 0);
                    }
                }
                break;
            case 4: //Foco em todos os equipamentos
                // if (fazenda.coordenadas !== "") {
                //     let fazCoord = JSON.parse(fazenda.coordenadas);
                //     equipamentos.forEach((e: IEquipamento) => {
                //         pushAllMarkers({
                //             id: e.id_equipamento,
                //             icon: equipaMarkerFoco(
                //                 e.ultima_operacao?.latitude,
                //                 e.ultima_operacao?.longitude,
                //                 e.ultima_operacao?.ignicaoON ? "green" : "red",
                //                 15,
                //                 e
                //             ),
                //         });
                //     });

                //     makeFit(
                //         fazCoord.lowX,
                //         fazCoord.lowY,
                //         fazCoord.highX,
                //         fazCoord.highY
                //     );
                // }
                break;
            case 5: //Foco operacional do equipamento
                let fazCoord = JSON.parse(fazenda.coordenadas);
                pushOverlayEquipa({
                    id: fazenda.id_fazenda,
                    rect: overlayControle(
                        fazCoord.lowX,
                        fazCoord.lowY,
                        fazCoord.highX,
                        fazCoord.highY
                    ),
                });
                if (opBBox !== null) {
                    if (polylines.length > 0) {
                        if (tipoOpParada.id_tipo_operacao !== undefined) {
                            polylines.forEach(n => {
                                pushParada({
                                    icon: paradaAdd(
                                        n.latitude,
                                        n.longitude,
                                        JSON.parse(equipamento.estilo).cor
                                    ),
                                });
                            });
                        } else {
                            pushPolygon({
                                id: equipamento.id_equipamento,
                                polyline: equipaCoordPolyline(
                                    polylines,
                                    JSON.parse(equipamento.estilo).cor,
                                    true
                                ),
                            });
                            pushFlagMarker({
                                id: uuidv4(), //polylines[0].id_operacao,
                                icon: equipaFlagMarker(
                                    polylines[0].latitude,
                                    polylines[0].longitude,
                                    true
                                ),
                            });
                            pushFlagMarker({
                                id: uuidv4(), //polylines[0].id_operacao,
                                icon: equipaFlagMarker(
                                    polylines[polylines.length - 1].latitude,
                                    polylines[polylines.length - 1].longitude,
                                    false
                                ),
                            });
                        }
                        makeFit(
                            opBBox.hLat,
                            opBBox.hLng,
                            opBBox.lLat,
                            opBBox.lLng
                        );
                        if (processo.side?.ativo) {
                            if (mapa.map) {
                                mapa.map.panBy(150, 0);
                            }
                        }
                        if (alertaRender.length > 0 && foco.subView === 1) {
                            alertaRender.forEach(n => {
                                pushNotify({
                                    icon: notifyAdd(n),
                                });
                            });
                        }
                    }
                }

                break;
            case 6: //Foco em antenas
                antenas.forEach((a: IAntenaModule) => {
                    pushMarkerAntena({
                        id: a.id_antena,
                        icon: antenaAdd(
                            JSON.parse(a.coordenadas).lat,
                            JSON.parse(a.coordenadas).lng,
                            "red"
                        ),
                    });
                });
                if (antena === "all") {
                    if (fazenda.coordenadas !== "") {
                        let fazCoord = JSON.parse(fazenda.coordenadas);
                        makeFit(
                            fazCoord.lowX,
                            fazCoord.lowY,
                            fazCoord.highX,
                            fazCoord.highY
                        );
                        if (processo.side?.ativo) {
                            if (mapa.map) {
                                mapa.map.panBy(150, 0);
                            }
                        }
                    }
                } else {
                    let antKey = antenas.findIndex(
                        (a: IAntenaModule) => a.id_antena === antena
                    );
                    if (antKey > -1) {
                        let coord = JSON.parse(antenas[antKey].coordenadas);
                        let center = new maps.LatLng(coord.lat, coord.lng);
                        mapa.map.setZoom(17);
                        mapa.map.panTo(center);
                    }
                }

                break;
            case 7: //Foco no tipo de operacao
                break;
        }
    }, [foco, processo.side?.ativo, equipamento, time, fazenda, polylines]);

    // useEffect(() => {
    //     if (foco.view === 3) {
    //         if (
    //             equipamento.ultima_operacao?.latitude &&
    //             equipamento.ultima_operacao?.longitude
    //         ) {
    //             pushMarker({
    //                 id: equipamento.id_equipamento,
    //                 icon: equipaMarkerFoco(
    //                     equipamento.ultima_operacao?.latitude,
    //                     equipamento.ultima_operacao?.longitude,
    //                     equipamento.ultima_operacao?.ignicaoON
    //                         ? "green"
    //                         : "red",
    //                     25,
    //                     equipamento
    //                 ),
    //             });
    //             let center = new maps.LatLng(
    //                 parseFloat(equipamento.ultima_operacao?.latitude),
    //                 parseFloat(equipamento.ultima_operacao?.longitude)
    //             );
    //             mapa.map.setZoom(17);
    //             mapa.map.panTo(center);
    //         }

    //         if (processo.side?.ativo) {
    //             if (mapa.map) {
    //                 mapa.map.panBy(150, 0);
    //             }
    //         }
    //     }
    // }, [foco, polylines]);

    useEffect(() => {
        if (foco.view === 7) {
            if (fazenda.coordenadas !== "") {
                let fazCoord = JSON.parse(fazenda.coordenadas);

                if (loader.filtros?.get?.success === true) {
                    if (polylineFiltro.length > 0) {
                        pushOverlayEquipa({
                            id: fazenda.id_fazenda,
                            rect: overlayControle(
                                fazCoord.lowX,
                                fazCoord.lowY,
                                fazCoord.highX,
                                fazCoord.highY
                            ),
                        });
                        setTimeout(() => {
                            if (tipoOpParada.id_tipo_operacao !== undefined) {
                                polylineFiltro.forEach((n: any) => {
                                    let paradaOp = n.polyline;
                                    paradaOp.forEach((l: any) => {
                                        pushParada({
                                            icon: paradaAdd(
                                                l.latitude,
                                                l.longitude,
                                                JSON.parse(
                                                    equipamentos[
                                                        equipamentos.findIndex(
                                                            (e: any) =>
                                                                e.id_equipamento ===
                                                                n.id
                                                        )
                                                    ].estilo
                                                ).cor
                                            ),
                                        });
                                    });
                                });
                            } else {
                                polylineFiltro.forEach((p: any) => {
                                    pushPolylineRender({
                                        id: p.id,
                                        polyline: equipaCoordPolyline(
                                            p.polyline,
                                            JSON.parse(
                                                equipamentos[
                                                    equipamentos.findIndex(
                                                        (e: any) =>
                                                            e.id_equipamento ===
                                                            p.id
                                                    )
                                                ].estilo
                                            ).cor
                                        ),
                                    });
                                });
                            }
                            if (tipoOpParada.id_tipo_operacao !== undefined) {
                            } else {
                            }
                        }, 500);
                    }
                    setStatusNull("get", "filtros");
                }

                setRefresh(uuidv4);

                makeFit(
                    fazCoord.lowX,
                    fazCoord.lowY,
                    fazCoord.highX,
                    fazCoord.highY
                );
            }
        }
    }, [
        tipoOp,
        tipoOpParada,
        safra,
        cultura,
        polylineFiltro,
        loader.filtros?.get?.success,
        time,
        operador,
        equipamentos,
        fazenda,
    ]);

    useEffect(() => {
        if (foco.view === 4) {
            if (fazenda.coordenadas !== "") {
                let fazCoord = JSON.parse(fazenda.coordenadas);
                equipamentos.forEach((e: IEquipamento) => {
                    if (e.estado !== "-1") {
                        pushAllMarkers({
                            id: e.id_equipamento,
                            icon: equipaMarkerFoco(
                                e.ultima_operacao?.latitude,
                                e.ultima_operacao?.longitude,
                                15,
                                e
                            ),
                        });
                    }
                });

                makeFit(
                    fazCoord.lowX,
                    fazCoord.lowY,
                    fazCoord.highX,
                    fazCoord.highY
                );
            }
            if (mapa.map) {
                mapa.map.panBy(0, 40);
            }
        }
    }, [foco, processo.side?.ativo, time, fazenda, equipamentos]);

    // useEffect(() => {
    //     let dias: any = [];
    //     polylines.forEach((p: any) => {
    //         let data = new Date(p.timestamp);
    //         if (
    //             dias.findIndex(
    //                 (d: any) =>
    //                     d.date ===
    //                     data.toLocaleDateString("pt-BR", {
    //                         day: "2-digit",
    //                         month: "2-digit",
    //                     })
    //             ) === -1
    //         ) {
    //             dias.push({
    //                 date: data.toLocaleDateString("pt-BR", {
    //                     day: "2-digit",
    //                     month: "2-digit",
    //                 }),
    //                 color: colorList[between(0, 19)],
    //             });
    //         }
    //     });
    //
    //     let matrizDia: any = [];
    //     dias.forEach((d: any) => {
    //         matrizDia.push({
    //             data: polylines.filter((p: any) => {
    //                 let data = new Date(p.timestamp);
    //                 return (
    //                     data.toLocaleDateString("pt-BR", {
    //                         day: "2-digit",
    //                         month: "2-digit",
    //                     }) === d.date
    //                 );
    //             }),
    //             cor: d.color,
    //         });
    //     });
    //
    //     setMatrizCor(matrizDia);
    // }, [polylines]);

    const mapContainer: any = useRef();
    return (
        <>
            <div
                ref={mapContainer}
                style={{
                    width: "100vw",
                    height: "100vh",
                    background:
                        "url(" +
                        process.env.REACT_APP_BASE_URL_ROOT +
                        "fnd.png)",
                }}
            ></div>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        alertaRender: store.alertaRender,
        antena: store.antena,
        antenas: store.antenas,
        equipamento: store.equipamento,
        equipamentos: store.equipamentos,
        equipaOverlay: store.equipaOverlay,
        fazendas: store.fazendas,
        fazenda: store.fazenda,
        foco: store.mapaFoco,
        talhao: store.talhao,
        loader: store.loader,
        processo: store.processo,
        operacoes: store.operacoes,
        opBBox: store.operacaoBBox,
        markers: store.marker,
        menuState: store.menu,
        processoListState: store.processos,
        polylines: store.polylines,
        time: store.time,
        tipoOp: store.tipoOp,
        tiposOp: store.tiposOp,
        safra: store.safra,
        cultura: store.cultura,
        polylineFiltro: store.polylineFiltro,
        polylineRender: store.polylineRender,
        operador: store.operador,

        talhoes: store.talhoesTablet,
        talhoesFiltro: store.talhaoFiltro,

        tipoOpParada: store.tipoOpParada,

        implementos: store.implementos,
        operadores: store.operadores,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            setEquipamento,
            ativarProcess,
            setTalhao,
            setFocus,
            pushMarker,
            remMarkers,
            pushAllMarkers,
            remAllMarkers,
            pushPolygon,
            remPolygons,
            pushMarkerAntena,
            remMarkerAntena,
            pushFlagMarker,
            remFlagMarker,
            pushOverlayEquipa,
            remOverlayEquipa,
            pushNotify,
            remNotify,
            pushParada,
            remParada,
            pushPolylineRender,
            remPolylineRender,
            setStatusSuccess,
            setRefresh,
            setStatusNull,
            setFazenda,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Map);
