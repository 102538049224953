import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getRegistroOpe } from "../../../store/operacoesAction";
import { Button, Empty, Menu, Modal, Tabs } from "antd";
import { useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Viewer from "./Operacoes/Viewer";
import MenuOpera from "./Operacoes/MenuOpera";
import ModalOpera from "./Operacoes/ModalOpera";
import { IStore } from "../../../store";
import { ITipoOp, ITipoOpList } from "../../../reducers/TipoOpReducer";
import {
    IEquipamento,
    IEquipamentoList,
} from "../../../reducers/EquipamentoReducer";
import { IFazenda, IFazendaList } from "../../../reducers/FazendaReducer";
import { ICultura, ICulturaList } from "../../../reducers/CulturaReducer";
import { ISafra, ISafraList } from "../../../reducers/SafraReducer";
import { Spinner } from "../../../styles/Main";
import { IOperador, IOperadorList } from "../../../reducers/OperadorReducer";
import { IStatusList, ITalhaoTablet } from "../../../store/types";
import {
    IImplemento,
    IImplementoList,
} from "../../../reducers/ImplementoReducer";
import { ColumnDef } from "@tanstack/react-table";
import { IRegistroOpe } from "../../../reducers/OperacaoReducer";
import { segParaHoras } from "../../../config/helpers";

import { Table as ReactTable } from "@tanstack/react-table";
import ModalAlertaParada from "./Operacoes/ModalAlertaParada";

const { SubMenu } = Menu;
const { TabPane } = Tabs;

export interface IOperacoesProps {
    getRegistroOpe: Function;

    modalFiltros: any;
    setModalFiltros: any;
    whichModal: any;
    setWhichModal: any;

    registroOpe: IRegistroOpe;
    loader: IStatusList;
    // items da tradução de filtro
    fazendaList: IFazendaList;
    tiposOp: ITipoOpList;
    equipamentos: IEquipamentoList;
    culturas: ICulturaList;
    safras: ISafraList;
    operadores: IOperadorList;
    talhoes: ITalhaoTablet[];
    implementos: IImplementoList;
}

function Operacoes(props: IOperacoesProps) {
    const {
        getRegistroOpe,

        registroOpe,
        loader,
        // items da tradução de filtro
        fazendaList,
        equipamentos,
        tiposOp,
        culturas,
        safras,
        operadores,
        talhoes,
        implementos,
    } = props;

    const [filtrosSelec, setFiltrosSelec] = useState("");
    const [ids, setIds] = useState<any>();

    const [tabAtiva, setTabAtiva] = useState(uuidv4());

    const [blob, setBlob] = useState("");

    const [tabs, setTabs] = useState([
        {
            title: "Em branco",
            content: {
                colunas: [
                    // {
                    //     Header: "ID",
                    //     accessor: "id_operacao",
                    // },
                    // {
                    //     Header: "Data/Hora",
                    //     accessor: "timestamp",
                    // },
                ],
                filtros: {
                    cultura: "",
                    datas: [],
                    equipamento: "",
                    fazendas: [],
                    safra: "",
                    operacoes: [],
                    // tipo_parada: "",
                    operador: "",
                    talhao: "",
                },
                paginacao: {
                    pagina: 0,
                    size: 10,
                },
                data: [],
            },
            key: tabAtiva,
        },
    ]);

    const [visivel, setVisivel] = useState(true);
    const [dialog, setDialog] = useState(2);
    const [filtrosDef, setFiltrosDef] = useState({});
    const [loadingExcel, setLoadingExcel] = useState(false);

    const [colunasLocal, setColunasLocal] = useState<any>([]);
    const [tabela, setTabela] = useState<any>();

    const [openModal, setOpenModal] = useState(false);
    const [configModal, setConfigModal] = useState<{
        titulo: string | React.ReactNode;
        conteudo: React.ReactNode;
    }>({
        titulo: "",
        conteudo: {},
    });

    let tzoffset = new Date().getTimezoneOffset() * 60000;

    const colunasOp = useMemo<ColumnDef<any>[]>(
        () => [
            {
                accessorKey: "fazenda_temp",
                id: "fazenda_temp",
                header: "Fazenda",
                cell: (info: any) => {
                    return fazendaList[
                        fazendaList.findIndex(
                            (f: IFazenda) => f.id_fazenda === info.getValue()
                        )
                    ].nome;
                },
                enableGrouping: false,
            },
            {
                accessorKey: "talhao_temp",
                id: "talhao_temp",
                header: "Talhão",
                cell: (info: any) => {
                    if (
                        info.getValue() === null ||
                        info.getValue() === undefined
                    ) {
                        return "Não Identificado";
                    } else {
                        return talhoes[
                            talhoes.findIndex(
                                (t: ITalhaoTablet) =>
                                    t.id_talhao === info.getValue().toString()
                            )
                        ].nome_talhao;
                    }
                },
                enableGrouping: false,
            },
            {
                accessorKey: "equipamento_temp",
                id: "equipamento_temp",
                header: "Equipamento",
                cell: (info: any) => {
                    if (info.getValue() !== null) {
                        let eKey: IEquipamento =
                            equipamentos[
                                equipamentos.findIndex(
                                    (e: IEquipamento) =>
                                        e.id_equipamento ===
                                        info.getValue().toString()
                                )
                            ];
                        return eKey.numero_patrimonio + " - " + eKey.descricao;
                    } else return "Não Identificado";
                },
                enableGrouping: false,
            },
            {
                accessorKey: "implemento_temp",
                id: "implemento_temp",
                header: "Implemento",
                cell: (info: any) => {
                    if (
                        info.getValue() === null ||
                        info.getValue() === undefined
                    ) {
                        return "Não Identificado";
                    } else {
                        return implementos[
                            implementos.findIndex(
                                (i: IImplemento) =>
                                    i.id_implemento ===
                                    info.getValue().toString()
                            )
                        ].descricao;
                    }
                },
                enableGrouping: false,
            },
            {
                accessorKey: "operador_temp",
                id: "operador_temp",
                header: "Operador",
                cell: (info: any) => {
                    if (
                        info.getValue() === null ||
                        info.getValue() === undefined
                    ) {
                        return "Não Identificado";
                    } else {
                        return operadores[
                            operadores.findIndex(
                                (o: IOperador) =>
                                    o.id_operador === info.getValue()
                            )
                        ].nome;
                    }
                },
                aggregatedCell: ({ getValue }: any) => {
                    return <></>;
                },
                enableGrouping: false,
            },
            {
                accessorKey: "tipo_operacao_temp",
                id: "tipo_operacao_temp",
                header: "Operação",
                cell: (info: any) => {
                    if (
                        info.getValue() === null ||
                        info.getValue() === undefined
                    ) {
                        return "Não Identificado";
                    } else {
                        return tiposOp[
                            tiposOp.findIndex(
                                (o: ITipoOp) =>
                                    o.id_tipo_operacao === info.getValue()
                            )
                        ].descricao;
                    }
                },
                aggregatedCell: ({ getValue }: any) => {
                    return <></>;
                },
                enableGrouping: false,
            },
            {
                accessorKey: "cultura_temp",
                id: "cultura_temp",
                header: "Cultura",
                cell: (info: any) => {
                    if (
                        info.getValue() === null ||
                        info.getValue() === undefined
                    ) {
                        return "Não Identificado";
                    } else {
                        return culturas[
                            culturas.findIndex(
                                (c: ICultura) =>
                                    c.id_cultura === info.getValue()
                            )
                        ].nomeCultura;
                    }
                },
                aggregatedCell: ({ getValue }: any) => {
                    return <></>;
                },
                enableGrouping: false,
            },
            {
                accessorKey: "safra_temp",
                id: "safra_temp",
                header: "Safra",
                cell: (info: any) => {
                    if (
                        info.getValue() === null ||
                        info.getValue() === undefined
                    ) {
                        return "Não Identificado";
                    } else {
                        return safras[
                            safras.findIndex(
                                (s: ISafra) => s.id_safra === info.getValue()
                            )
                        ].descricao;
                    }
                },
                aggregatedCell: ({ getValue }: any) => {
                    return <></>;
                },
                enableGrouping: false,
            },
            {
                accessorKey: "marcador_inicial",
                id: "marcador_inicial",
                header: "Horimetro Inicial",
                cell: (info: any) => {
                    let paraMin = info.getValue() * 60;
                    let paraHoras = Math.floor(info.getValue());
                    let min: any = Math.trunc(paraMin % 60);
                    return paraHoras + "h" + min + "m";
                },
                enableGrouping: false,
                aggregatedCell: ({ getValue }: any) => {
                    return <></>;
                },
            },
            {
                accessorKey: "marcador_final",
                id: "marcador_final",
                header: "Horimetro Final",
                cell: (info: any) => {
                    if (
                        info.getValue() === null ||
                        info.getValue() === undefined
                    ) {
                        return "Operação em Andamento";
                    } else {
                        let paraMin = info.getValue() * 60;
                        let paraHoras = Math.floor(info.getValue());
                        let min: any = Math.trunc(paraMin % 60);
                        return paraHoras + "h" + min + "m";
                    }
                },
                enableGrouping: false,
                aggregatedCell: ({ getValue }: any) => {
                    return <></>;
                },
            },
            {
                accessorKey: "data_inicial",
                id: "data_inicial",
                header: "Data Inicial",
                cell: (info: any) => {
                    let newDate = info.getValue().split("T")[0];
                    let arrDate = newDate.split("-");
                    return (
                        arrDate[2] +
                        "/" +
                        arrDate[1] +
                        "/" +
                        arrDate[0] +
                        " " +
                        info.getValue().split("T")[1]
                    );
                },
                enableGrouping: false,
            },
            {
                accessorKey: "data_final",
                id: "data_final",
                header: "Data Final",
                cell: (info: any) => {
                    if (
                        info.getValue() === null ||
                        info.getValue() === undefined
                    ) {
                        return "Operação em Andamento";
                    } else {
                        let newDate = info.getValue().split("T")[0];
                        let arrDate = newDate.split("-");
                        return (
                            arrDate[2] +
                            "/" +
                            arrDate[1] +
                            "/" +
                            arrDate[0] +
                            " " +
                            info.getValue().split("T")[1]
                        );
                    }
                },
                enableGrouping: false,
            },
            {
                accessorKey: "tempo_parado",
                id: "tempo_parado",
                header: "Tempo Parado",
                cell: (info: any) => {
                    if (
                        info.getValue() === null ||
                        info.getValue() === undefined
                    ) {
                        return "Operação em Andamento";
                    } else {
                        return (
                            segParaHoras(info.getValue(), "horas").h +
                            ":" +
                            segParaHoras(info.getValue(), "horas").m +
                            ":" +
                            segParaHoras(info.getValue(), "horas").s
                        );
                    }
                },
                aggregatedCell: ({ getValue }: any) => {
                    if (getValue() === null || getValue() === undefined) {
                        return "Operação em Andamento";
                    } else {
                        return (
                            segParaHoras(getValue(), "horas").h +
                            ":" +
                            segParaHoras(getValue(), "horas").m +
                            ":" +
                            segParaHoras(getValue(), "horas").s
                        );
                    }
                },
                aggregationFn: "sum",
                enableGrouping: false,
            },
            {
                accessorKey: "tempo_andando",
                id: "tempo_andando",
                header: "Tempo em Movimento",
                cell: (info: any) => {
                    if (
                        info.getValue() === null ||
                        info.getValue() === undefined
                    ) {
                        return "Operação em Andamento";
                    } else {
                        return (
                            segParaHoras(info.getValue(), "horas").h +
                            ":" +
                            segParaHoras(info.getValue(), "horas").m +
                            ":" +
                            segParaHoras(info.getValue(), "horas").s
                        );
                    }
                },
                aggregatedCell: ({ getValue }: any) => {
                    if (getValue() === null || getValue() === undefined) {
                        return "Operação em Andamento";
                    } else {
                        return (
                            segParaHoras(getValue(), "horas").h +
                            ":" +
                            segParaHoras(getValue(), "horas").m +
                            ":" +
                            segParaHoras(getValue(), "horas").s
                        );
                    }
                },
                aggregationFn: "sum",
                enableGrouping: false,
            },
            {
                accessorKey: "tempo_total",
                id: "tempo_total",
                header: "Tempo Total",
                cell: (info: any) => {
                    if (
                        info.getValue() === null ||
                        info.getValue() === undefined
                    ) {
                        return "Operação em Andamento";
                    } else {
                        return (
                            segParaHoras(info.getValue(), "horas").h +
                            ":" +
                            segParaHoras(info.getValue(), "horas").m +
                            ":" +
                            segParaHoras(info.getValue(), "horas").s
                        );
                    }
                },
                aggregatedCell: ({ getValue }: any) => {
                    if (getValue() === null || getValue() === undefined) {
                        return "Operação em Andamento";
                    } else {
                        return (
                            segParaHoras(getValue(), "horas").h +
                            ":" +
                            segParaHoras(getValue(), "horas").m +
                            ":" +
                            segParaHoras(getValue(), "horas").s
                        );
                    }
                },
                aggregationFn: "sum",
                enableGrouping: false,
            },
            {
                accessorKey: "consumo_combustivel",
                id: "consumo_combustivel",
                header: "Consumo",
                cell: (info: any) => {
                    if (
                        info.getValue() === null ||
                        info.getValue() === undefined
                    ) {
                        return "Operação em Andamento";
                    } else return info.getValue().toFixed(2) + "L";
                },
                aggregatedCell: ({ getValue }: any) => {
                    if (getValue() === null || getValue() === undefined) {
                        return "Operação em Andamento";
                    } else return getValue().toFixed(2) + "L";
                },
                enableGrouping: false,
                aggregationFn: "sum",
            },
            {
                accessorKey: "ha",
                id: "ha",
                header: "Hectares",
                cell: (info: any) => {
                    if (
                        info.getValue() === null ||
                        info.getValue() === undefined
                    ) {
                        return "Operação em Andamento";
                    } else return info.getValue().toFixed(2) + " ha";
                },
                aggregatedCell: ({ getValue }: any) => {
                    if (getValue() === null || getValue() === undefined) {
                        return "Operação em Andamento";
                    } else return getValue().toFixed(2) + " ha";
                },
                aggregationFn: "sum",
                enableGrouping: false,
            },
            {
                accessorKey: "largura_operacao",
                id: "largura_operacao",
                header: "Largura Trabalho",
                cell: (info: any) => {
                    if (
                        info.getValue() === null ||
                        info.getValue() === undefined
                    ) {
                        return "Não Identificado";
                    } else return info.getValue().toFixed(2) + " m";
                },
                enableGrouping: false,
            },
            {
                accessorKey: "registro_operacao_parada",
                id: "registro_operacao_parada",
                header: "Paradas",
                cell: ({ row }) => {
                    return (
                        <Button
                            style={{
                                color: "black",
                            }}
                            type="link"
                            size="small"
                            disabled={
                                row.original.registro_operacao_parada.length ===
                                0
                            }
                            onClick={() => {
                                return handleExibir(row.original, 0);
                            }}
                        >
                            {row.original.registro_operacao_parada.length}
                        </Button>
                    );
                    // if (info.getValue() !== undefined) {
                    //     return info.getValue().length;
                    // } else return "0";
                },
                enableGrouping: false,
            },
            {
                accessorKey: "registro_operacao_alerta",
                id: "registro_operacao_alerta",
                header: "Alertas",
                cell: ({ row }) => {
                    return (
                        <Button
                            style={{
                                color: "black",
                            }}
                            type="link"
                            size="small"
                            disabled={
                                row.original.registro_operacao_alerta.length ===
                                0
                            }
                            onClick={() => {
                                return handleExibir(row.original, 1);
                            }}
                        >
                            {row.original.registro_operacao_alerta.length}
                        </Button>
                    );
                    // if (info.getValue() !== undefined) {
                    //     return info.getValue().length;
                    // } else return "0";
                },
                enableGrouping: false,
            },
        ],
        []
    );

    function handleExibir(value: any, type: any) {
        let equip: String =
            equipamentos[
                equipamentos.findIndex(
                    (e: IEquipamento) =>
                        e.id_equipamento === value.equipamento_temp
                )
            ].numero_patrimonio +
            " - " +
            equipamentos[
                equipamentos.findIndex(
                    (e: IEquipamento) =>
                        e.id_equipamento === value.equipamento_temp
                )
            ].descricao;
        let ope: any =
            value.tipo_operacao_temp === null ||
            value.tipo_operacao_temp === undefined
                ? "Não Identificado"
                : tiposOp[
                      tiposOp.findIndex(
                          (o: ITipoOp) =>
                              o.id_tipo_operacao === value.tipo_operacao_temp
                      )
                  ].descricao;
        setConfigModal({
            titulo:
                type === 0 ? (
                    <>
                        Listar Paradas <br />
                        Equipamento: {equip} <br />
                        Operação: {ope}
                    </>
                ) : (
                    <>
                        Listar Alertas <br />
                        Equipamento: {equip} <br />
                        Operação: {ope}
                    </>
                ),
            conteudo: (
                <ModalAlertaParada
                    setOpenModal={setOpenModal}
                    cont={value}
                    tipoModal={type}
                />
            ),
        });
        setOpenModal(true);
    } //

    useEffect(() => {}, [tabs]);

    useEffect(() => {
        let aba =
            tabs[
                tabs.findIndex((a: any) => {
                    return a.key === tabAtiva;
                })
            ].content;
        if (
            Object.keys(aba.filtros.datas).length > 0 &&
            aba.filtros.datas !== null
        ) {
            let dataI: any = new Date(aba.filtros.datas[0]);
            let dataF: any = new Date(aba.filtros.datas[1]);

            getRegistroOpe({
                dataIni: new Date(dataI - tzoffset).toISOString().split(".")[0],
                dataFin: new Date(dataF - tzoffset).toISOString().split(".")[0],
                fazendas:
                    aba.filtros.fazendas.length > 0
                        ? aba.filtros.fazendas.join(",")
                        : "",
                equipamento: aba.filtros.equipamento,
                cultura: aba.filtros.cultura,
                safra: aba.filtros.safra,
                operacoes:
                    aba.filtros.operacoes.length > 0
                        ? aba.filtros.operacoes.join(",")
                        : "",
                operador: aba.filtros.operador,
                talhao: aba.filtros.talhao,
                page: aba.paginacao.pagina,
                size: aba.paginacao.size,
            });
        } else {
        }
    }, [tabs, tabAtiva]);

    async function expExcel2(table: ReactTable<any>) {
        let colunas = colunasLocal.map((c: any) => {
            return {
                name: c.header,
                filterButton: true,
            };
        });

        let abaAtual: any =
            tabs[
                tabs.findIndex((a: any) => {
                    return a.key === tabAtiva;
                })
            ].content;

        if (registroOpe.total_registros !== undefined) {
            if (
                Object.keys(abaAtual.filtros.datas).length > 0 &&
                abaAtual.filtros.datas !== null
            ) {
                let dataI: any = new Date(abaAtual.filtros.datas[0]);
                let dataF: any = new Date(abaAtual.filtros.datas[1]);

                var colunasList = "";
                colunasLocal.forEach((c: any) => {
                    colunasList += c.accessorKey + ",";
                });

                const keys = Object.keys(abaAtual.filtros);
                var urlApi =
                    "api/registrooperacao/exportar?dataIni=" +
                    new Date(dataI - tzoffset).toISOString().split(".")[0] +
                    "&dataFin=" +
                    new Date(dataF - tzoffset).toISOString().split(".")[0] +
                    "&colunas=" +
                    colunasList +
                    "&";
                keys.forEach((key, index) => {
                    if (
                        abaAtual.filtros[key] === "" ||
                        abaAtual.filtros[key] === "null"
                    ) {
                    } else {
                        if (key !== "datas")
                            urlApi += key + "=" + abaAtual.filtros[key] + "&";
                    }
                });

                setLoadingExcel(true);
                const rowsExcel = await fetch(
                    `${process.env.REACT_APP_BASE_URL_API}` + urlApi,
                    {
                        method: "GET",
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                            "Content-Type": "application/json;charset=UTF-8",
                        },
                    }
                );

                const rowsExcelJSON = JSON.parse(await rowsExcel.text());

                setLoadingExcel(false);

                let cols: any = table.getAllColumns();

                let excelT = rowsExcelJSON.dados.map((row: any) => {
                    let temp = JSON.parse(JSON.stringify(row));
                    cols.forEach((col: any) => {
                        let cellValue = row[col.columnDef.accessorKey];
                        if (
                            col.columnDef.accessorKey === "ha" ||
                            col.columnDef.accessorKey === "consumo_combustivel"
                        ) {
                            if (cellValue === null || cellValue === undefined) {
                                temp[col.columnDef.accessorKey] =
                                    "Operação em Andamento";
                            } else {
                                temp[col.columnDef.accessorKey] = Number(
                                    cellValue.toFixed(2)
                                );
                            }
                        } else if (
                            col.columnDef.accessorKey ===
                            "registro_operacao_alerta"
                        ) {
                            temp[col.columnDef.accessorKey] =
                                row["count_alertas"];
                        } else if (
                            col.columnDef.accessorKey ===
                            "registro_operacao_parada"
                        ) {
                            temp[col.columnDef.accessorKey] =
                                row["count_paradas"];
                        } else if (
                            col.columnDef.accessorKey === "largura_operacao"
                        ) {
                            if (cellValue === null || cellValue === undefined) {
                                temp[col.columnDef.accessorKey] =
                                    "Não Identificado";
                            } else {
                                temp[col.columnDef.accessorKey] = Number(
                                    cellValue.toFixed(2)
                                );
                            }
                        } else {
                            temp[col.columnDef.accessorKey] =
                                col.columnDef.cell({
                                    getValue: () => cellValue,
                                });
                        }
                    });

                    return temp;
                });

                const ExcelJS = require("exceljs");
                const workbook = new ExcelJS.Workbook();
                workbook.creator = "AGROCHIP";
                workbook.created = new Date();
                const worksheet = workbook.addWorksheet(
                    "Planilha Agrochip",
                    {}
                );

                let linhas = excelT.map((d: any) => {
                    let keys: any = [];
                    colunasLocal.forEach((c: any) => {
                        keys.push(d[c.accessorKey]);
                    });
                    return keys;
                });

                worksheet.addTable({
                    name: "Tabela",
                    ref: "A2",
                    style: {
                        theme: "TableStyleMedium22",
                        showRowStripes: true,
                    },
                    headerRow: true,
                    totalsRow: true,
                    columns: colunas,
                    rows: linhas,
                });

                worksheet.headerFooter.oddFooter = "Page &P of &N";
                worksheet.headerFooter.addHeader =
                    '&C&KCCCCCC&"Nunito"Agrochip';

                worksheet.columns.forEach((column: any, i: any) => {
                    var maxLength = 0;
                    column["eachCell"]({ includeEmpty: true }, (cell: any) => {
                        var columnLength = cell.value
                            ? cell.value.toString().length
                            : 10;
                        if (columnLength > maxLength) {
                            maxLength = columnLength;
                        }
                    });
                    column.width = maxLength < 10 ? 10 : maxLength + 3;
                });

                workbook.xlsx.writeBuffer().then(function (data: any) {
                    var blob = new Blob([data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
                    });
                    const FileSaver = require("file-saver");
                    FileSaver.saveAs(blob, "Planilha Agrochip.xlsx");
                });
            }
        }
    }

    useEffect(() => {
        setColunasLocal(
            colunasOp.filter((col: any) =>
                tabs[
                    tabs.findIndex((t: any) => t.key === tabAtiva)
                ].content.colunas.some((c: any) => col.header === c.header)
            )
        );
    }, [tabs, tabAtiva]);

    return (
        <>
            <div>
                <MenuOpera
                    setTabs={setTabs}
                    tabs={tabs}
                    tabAtiva={tabAtiva}
                    setVisivel={setVisivel}
                    setDialog={setDialog}
                    setTabAtiva={setTabAtiva}
                    exportXls={expExcel2}
                    tabela={tabela}
                    haRegistro={
                        registroOpe.dados !== undefined &&
                        registroOpe.dados.length > 0
                    }
                />
                <Tabs
                    hideAdd={true}
                    onChange={(o: any) => {
                        setTabAtiva(o);
                    }}
                    tabBarStyle={{ paddingLeft: "10px" }}
                    activeKey={tabs.length === 0 ? tabs[0].key : tabAtiva}
                    type="editable-card"
                    onEdit={(e: any) => {
                        if (typeof e === "string") {
                            let newTabs = tabs.filter((t: any) => {
                                return e !== t.key;
                            });
                            let keyFechando = tabs.findIndex(
                                (t: any) => t.key === e
                            );
                            if (e === tabAtiva) {
                                if (keyFechando === 0) {
                                    setTabAtiva(tabs[keyFechando + 1].key);
                                } else {
                                    setTabAtiva(tabs[keyFechando - 1].key);
                                }
                            }
                            setTabs(newTabs);
                        }
                    }}
                    className="opera-tab"
                >
                    {tabs.map((pane: any) => {
                        return (
                            <TabPane
                                tab={pane.title}
                                key={pane.key}
                                closable={tabs.length !== 1}
                            >
                                {pane.content.filtros.datas.length === 0 ? (
                                    <Empty
                                        description="Nenhum filtro foi selecionado."
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    />
                                ) : registroOpe.dados !== undefined &&
                                  registroOpe.dados.length > 0 ? (
                                    colunasLocal.length > 0 ? (
                                        <Viewer
                                            columns={colunasLocal}
                                            data={registroOpe.dados}
                                            abas={tabs}
                                            setAbas={setTabs}
                                            abaAtiva={tabAtiva}
                                            setBlob={setBlob}
                                            blob={blob}
                                            setTabela={setTabela}
                                        />
                                    ) : (
                                        <Empty
                                            description="Nenhuma coluna foi selecionada."
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        />
                                    )
                                ) : (
                                    <Empty
                                        description="Sem dados para exibição"
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    />
                                )}
                            </TabPane>
                        );
                    })}
                </Tabs>
                <ModalOpera
                    setFiltrosSelec={setFiltrosSelec}
                    filtrosSelec={filtrosSelec}
                    setIds={setIds}
                    setFiltrosDef={setFiltrosDef}
                    //
                    visivel={visivel}
                    dialog={dialog}
                    colunas={colunasOp}
                    setColunas={setColunasLocal}
                    colunasOpe={colunasLocal}
                    abaAtiva={tabAtiva}
                    abasList={tabs}
                    setAbasList={setTabs}
                    setVisivel={setVisivel}
                />
                <Modal
                    destroyOnClose
                    width="900px"
                    visible={openModal}
                    zIndex={1031}
                    title={configModal.titulo}
                    maskStyle={{ backdropFilter: "blur(1px)" }}
                    bodyStyle={{
                        padding: "15px",
                    }}
                    footer={null}
                    centered
                    onCancel={() => setOpenModal(false)}
                >
                    {configModal.conteudo}
                </Modal>
            </div>
            <div
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    backdropFilter: "blur(3px)",
                    zIndex: 1031,
                    display:
                        loadingExcel || loader.registroOpe?.get?.loading
                            ? "flex"
                            : "none", //
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Spinner view={true}>
                    <i
                        className="fas fa-spinner"
                        style={{ fontSize: "50px", color: "#FFF" }}
                    ></i>
                </Spinner>
            </div>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        registroOpe: store.registroOpe,
        loader: store.loader,
        fazendaList: store.fazendas,
        equipamentos: store.equipamentos,
        tiposOp: store.tiposOp,
        culturas: store.culturas,
        safras: store.safras,
        operadores: store.operadores,
        talhoes: store.talhoesTablet,
        implementos: store.implementos,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({ getRegistroOpe }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Operacoes);
